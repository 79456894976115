import createViewport from '../utils/createViewport';
import { useDocumentViewerContext } from '../../../contexts/DocumentViewerContextProvider';
import useAddBlankReport from './useAddBlankReport';
import { useGlobalContext } from '../../../contexts/GlobalContextProvider';
import { useDocumentViewerPermissionsContext } from '../../../contexts/DocumentViewerPermissionsContextProvider';
import getStudyPreliminaryDiagnosticReports from '../../../utils/getStudyPreliminaryDiagnosticReports';
import getStudyFinalDiagnosticReports from '../../../utils/getStudyFinalDiagnosticReports';
import { sortByLastUpdated } from '../utils/sortByLastUpdate';
import useGetSearchParams from '../../../hooks/useGetSearchParams';
import useCheckAccess from '../../../hooks/useCheckAccess';
import { DIAGNOSTIC_REPORT_STATUS } from '../../../consts/consts';

import { useStudiesStore } from '../../../store/studies';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const useOpenStartDocument = () => {
	const isDocumentViewer = window.location.pathname === '/document-viewer-v3';
	if (!isDocumentViewer) {
		return {};
	}

	const { setViewports } = useDocumentViewerContext();
	const { addBlankReport } = useAddBlankReport();
	const { practitioner } = useGlobalContext();
	const diagnosticReportsList = useStudiesStore(state => state.diagnosticReportsList);
	const { popOutID, documentId, studyId } = useGetSearchParams();
	const { hasFullAccess } = useCheckAccess();
	const { DOCUMENT_VIEWER_PERMISSION } = useDocumentViewerPermissionsContext();
	const galaxyDvWfaexecutionstatusFlag = useBooleanFlagValue('galaxy-dv-wfaexecutionstatus-flag');
	const galaxyDvUndoSignOff = useBooleanFlagValue('galaxy-dv-undo-sign-off');

	const openStartDocument = async () => {
		const studyPreliminaryDiagnosticReports = getStudyPreliminaryDiagnosticReports({
			studyId,
			diagnosticReports: diagnosticReportsList,
		})
			.sort(sortByLastUpdated)
			.filter(d => !d.isBlank);
		const studyFinalDiagnosticReports = getStudyFinalDiagnosticReports({
			studyId,
			diagnosticReports: diagnosticReportsList,
		}).sort(sortByLastUpdated);
		if (popOutID) {
			return;
		}
		const filteredStudyPreliminaryDiagnosticReportsByPractitioner = studyPreliminaryDiagnosticReports.filter(
			d =>
				d?.meta?.extension?.find(e => e?.url?.includes('lastUpdateUser'))?.valueReference?.id ===
				practitioner?.id
		);

		let startDocument =
			studyFinalDiagnosticReports.length && DOCUMENT_VIEWER_PERMISSION.FINAL_REPORTS
				? studyFinalDiagnosticReports[0]
				: hasFullAccess && DOCUMENT_VIEWER_PERMISSION.PRELIMINARY_REPORTS
				? filteredStudyPreliminaryDiagnosticReportsByPractitioner.length
					? filteredStudyPreliminaryDiagnosticReportsByPractitioner[0]
					: studyPreliminaryDiagnosticReports.length
					? studyPreliminaryDiagnosticReports[0]
					: null
				: null;

		if (
			JSON.parse(documentId) !== null &&
			studyPreliminaryDiagnosticReports.find(document => document?.id === documentId) &&
			galaxyDvUndoSignOff
		) {
			startDocument = studyPreliminaryDiagnosticReports.find(document => document?.id === documentId);
		}

		const isReportSignInProcess =
			galaxyDvWfaexecutionstatusFlag &&
			startDocument?.extension?.find(item => item?.url === 'wfaExecutionStatus')?.valueString === 'started';

		const signDelayStatus = startDocument?.extension?.find(element =>
			element?.url?.includes('signDelayStatus')
		)?.valueString;

		if (startDocument) {
			const startViewport = createViewport({
				...startDocument,
				isReadonly:
					(galaxyDvUndoSignOff &&
						startDocument.status === DIAGNOSTIC_REPORT_STATUS.UNKNOWN &&
						signDelayStatus === 'started') ||
					isReportSignInProcess,
				documentId: startDocument.id,
				documentResourceType: startDocument.resourceType,
			});
			setViewports([startViewport]);
			localStorage.setItem('openedReportID', startViewport?.documentId);
		} else if (hasFullAccess) {
			await addBlankReport();
		}
	};

	return {
		openStartDocument,
	};
};

export default useOpenStartDocument;
