import { useCallback } from 'react';
import { logError, searchScopes, useFhirDataLoader, useConfig } from '@worklist-2/core/src';
import { useValueSetContext } from '../../../contexts/ValueSetContextProvider';
import { useTemplateManagerContext } from '../../../contexts/TemplateManagerContextProvider';
import useGetSearchParams from '../../../hooks/useGetSearchParams';
import getTemplatesInfo from '../../../api/getTemplatesInfo';
import createQueryParam from '../../../utils/createQueryParam';
import getBestMatchTemplateWithContent from '../../../api/getBestMatchTemplateWithContent';
import { useStudiesStore } from '../../../store/studies';
import { useValueSets } from '@worklist-2/ui/src/stores/valueSetStore';
import { groupTemplatesWithAllModalityLast } from '../../../utils/templateManager';

const useBestMatchTemplateForStudy = () => {
	const { setTemplatesLoading, setBestMatchedTemplate, setAllBestMatchedTemplate } = useTemplateManagerContext();
	const { studyId, internalManagingOrganizationID } = useGetSearchParams();
	const { bodyPartList, lateralityList, statusList } = useValueSetContext();
	const { fetchDataForBMT } = useValueSets();
	const _config = useConfig();

	const currentStudyFromStore = useStudiesStore(state => state.currentStudy);
	const setReportContentLoaded = useStudiesStore(state => state.setReportContentLoaded);

	const { load: templateLoader } = useFhirDataLoader({
		endpoint: `Organization/${internalManagingOrganizationID}/template`,
	});

	const fhirStudyLoader = useFhirDataLoader({
		scope: searchScopes.studyRegular,
	});

	const getBestMatchTemplate = useCallback(
		async (onlyOneTemplate, searchKey = '', currentStudyParam = null) => {
			const currentStudy = currentStudyParam ?? currentStudyFromStore; // Use nullish coalescing
			const count = onlyOneTemplate ? 1 : 50;
			if (!studyId || !internalManagingOrganizationID) {
				setBestMatchedTemplate(null);
				setAllBestMatchedTemplate([]);
				return;
			}

			setTemplatesLoading(true);
			try {
				let ranked = [];

				if (!currentStudy) {
					onlyOneTemplate && setBestMatchedTemplate(null);
					setTemplatesLoading(false);
					setReportContentLoaded(true); // should not be call in image viewer
					return;
				}
				const valueSets = await fetchDataForBMT();
				if (onlyOneTemplate) {
					ranked = await getBestMatchTemplateWithContent(_config, internalManagingOrganizationID, {
						criteria: createQueryParam(
							currentStudy,
							[valueSets.Bodypart, valueSets.Laterality, valueSets.Status],
							studyId,
							'RP',
							searchKey || null,
							count
						),
					});
				} else {
					ranked = await getTemplatesInfo(templateLoader, {
						criteria: createQueryParam(
							currentStudy,
							[valueSets.Bodypart, valueSets.Laterality, valueSets.Status],
							studyId,
							'RP',
							searchKey || null,
							count
						),
					});
				}
				if (ranked?.length === 0) {
					setReportContentLoaded(true);
					onlyOneTemplate && setBestMatchedTemplate(null);
					return;
				}

				if (ranked && ranked.length) {
					ranked.sort((a, b) => b.matchpoint - a.matchpoint);
					const topTemplate = ranked[0];
					if (topTemplate.template) {
						const { htmlData, templateURL } = JSON.parse(topTemplate.template);
						if (htmlData || templateURL) {
							onlyOneTemplate && setBestMatchedTemplate(topTemplate);
						} else {
							onlyOneTemplate && setBestMatchedTemplate(null);
						}
						setAllBestMatchedTemplate(groupTemplatesWithAllModalityLast(ranked));
					}
				} else {
					onlyOneTemplate && setBestMatchedTemplate(null);
					setAllBestMatchedTemplate([]);
				}
			} catch (error) {
				logError('useBestMatchTemplateForStudy', 'Error loading templates:', error);
				setBestMatchedTemplate(null);
				setAllBestMatchedTemplate([]);
			} finally {
				setTemplatesLoading(false);
			}
		},
		[
			fetchDataForBMT,
			currentStudyFromStore,
			studyId,
			internalManagingOrganizationID,
			setTemplatesLoading,
			fhirStudyLoader,
			templateLoader,
			bodyPartList,
			lateralityList,
			statusList,
			setBestMatchedTemplate,
			setAllBestMatchedTemplate,
		]
	);

	return { getBestMatchTemplate };
};

export default useBestMatchTemplateForStudy;
